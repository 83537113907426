import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { Banner } from "../Banner/Banner";
import { RouteAuthentication } from "../RouteAuthentication/RouteAuthentication";
import "./ActivityMenu.css";

const ActivityMenu = () => {
  const activityType = useSelector((state) => state.activity.activityType);
  console.log("activityType: ", activityType);
  const activity_array = {
    activity_0_list: [
      {
        path: "./img/Certified_Teachers/Certified_teacher.png",
        transform: "",
        opacity: "",
      },
    ],
    activity_1_list: [
      {
        path: "./img/course-6.jpg",
        transform: "",
        opacity: "",
      },
    ],
    activity_2_list: [
      { path: "./img/Cognitive-Academic-learning/img3.jpeg",transform: "translate3d(0px, 0px, 0px)",
      "transition-duration": "0ms",
      opacity: 1, },
      { path: "./img/Cognitive-Academic-learning/img1.jpeg",transform: "translate3d(-1281px, 0px, 0px)",
      "transition-duration": "0ms",
      opacity: 0, },
      { path: "./img/Cognitive-Academic-learning/img2.jpeg",transform: "translate3d(-2562px, 0px, 0px)",
      "transition-duration": "0ms",
      opacity: 0, },
    ],
    activity_3_list: [
      { path: "./img/Parent-App/Parent-App.jpeg", transform: "", opacity: "" },
    ],
  };

  useEffect(() => {
    console.log(activity_array[`activity_${activityType}_list`], "===");
    activity_array[`activity_${activityType}_list`].map((item) =>
      console.log("item: ", item)
    );
  }, [activityType]);

  return (
    <div className="page">
      <Header />
      {/* <Banner slideclass="course-slide" /> */}
      <section className="about-us">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2 className="color-orange">
                  {activityType === 0 && "Certified Teachers"}
                  {activityType === 1 && "Self Learning Environment"}
                  {activityType === 2 && "Cognitive / Academic Learning"}
                  {activityType === 3 &&
                    "Parents App to check Child Performance"}
                </h2>
                {/* <p>Lorem ipsum dolor sit amet, ipsum dolor sit amet</p> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              {(
                <div
                  className={`swiper-container banner-slider swiper-container-fade swiper-container-horizontal activity-slide`}
                >
                  <div
                    className="swiper-wrapper activity-slide"
                    style={{ "transition-duration": "0ms" }}
                  >
                    {activity_array[`activity_${activityType}_list`].map(
                      (item) => (
                        <div
                          className="swiper-slide swiper-slide-active activity-slide"
                          style={{
                            width: "1281px",
                            opacity: item.opacity,
                            transform: item.transform,
                          }}
                        >
                          <div className="banner-slide">
                            <figure>
                              <img src={item.path} className="activity_banner_img" />
                            </figure>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="banner-button-prev swiper-button-disabled">
                    <i className="fa fa-chevron-left"></i>
                  </div>
                  <div className="banner-button-next">
                    <i className="fa fa-chevron-right"></i>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-6">
              <div
                className="course-content wow fadeInUp"
                data-wow-delay="0.6s"
                style={{
                  visibility: "hidden",
                  "animation-delay": "0.6s",
                  "animation-name": "none",
                }}
              >
                {/* <h3>Our Philosophy</h3> */}

                {activityType === 0 ? (
                  <p>
                    All tachers are well qualified to provide best quality of
                    education
                  </p>
                ) : null}
                {activityType === 1 ? (
                  <>
                    <p>
                      Students become more confident learners as a result of
                      self-learning. Because they are independent learners, they
                      are capable of attempting to acquire new skills on their
                      own, which can be a significant benefit to the pupils.
                    </p>
                  </>
                ) : null}
                {activityType === 2 ? (
                  <>
                    <p>
                      Applee Tree Pre School more focuses on an immersive and
                      active process that engages students senses in a
                      constructive and long – lasting way.
                    </p>
                  </>
                ) : null}
                {activityType === 3 ? (
                  <>
                    <p>
                      The Jr kg Lab is designed for vibrant and active toddlers.
                    </p>
                    <p>
                      We provide live access of app to parents to ensure
                      education quality
                    </p>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ActivityMenu;
