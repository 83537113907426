
import { Header } from '../Header/Header';
import { Footer } from  '../Footer/Footer';
import { RouteAuthentication } from "../RouteAuthentication/RouteAuthentication";

const Page = () => {
  return (
    <div className="page">
     <Header />
     <section className="about-us">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2 className="color-orange">About Us</h2>
                {/* <p>Lorem ipsum dolor sit amet, ipsum dolor sit amet</p> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div
                className="about-image wow fadeInLeft"
                data-wow-delay="0.3s"
                style={{
                  visibility: "hidden",
                  "animation-delay": "0.3s",
                  "animation-name": "none",
                }}
              >
                <img src="./img/about.jpg" />
              </div>
            </div>

            <div className="col-md-6">
              <div
                className="about-content wow fadeInUp"
                data-wow-delay="0.6s"
                style={{
                  visibility: "hidden",
                  "animation-delay": "0.6s",
                  "animation-name": "none",
                }}
              >
                <h3>Our Philosophy</h3>

                <p>
                  We, Applee Tree Pre School situated at Kalyan, Maharashtra,
                  focus on building a strong foundation for learning and
                  developing the young minds. Providing high quality education,
                  right from the preschool which is or goal and commitment.
                  While learning comes naturally to children with simpler
                  teaching methods. We facilitate and build on this natural
                  learning ability. We take ourselves as the initiative to give
                  something back to society.
                </p>
              </div>

              <div className="about-social-links">
                <a href="https://www.facebook.com/appleetreepreschool/" target="_blank">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="https://youtube.com/@appletree5735?feature=shared" target="_blank">
                  <i className="fa fa-youtube"></i>
                </a>
                <a href="#">
                  <i className="fa fa-linkedin"></i>
                </a>
                <a href="#">
                  <i className="fa fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
     <Footer />
    </div>
  );
}

export default RouteAuthentication(Page);
