import React from "react";
import "./Faculty.css";

export const Faculty = () => {
  return (
    <>
      <section className="faculty" id="teachers">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2 className="color-orange">Qualified Teachers</h2>
              </div>
            </div>
          </div>

          <div className="row" style={{"justify-content": "center"}}>
            <div className="col-lg-4 col-md-6">
              <div
                className="faculty-single faculty-orange wow fadeInUp"
                data-wow-delay="0.3s"
                style={{
                "visibility": "hidden",
                "animation-delay": "0.3s",
                "animation-name": "none"
              }}
              >
                <figure className="faculty_img">
                  <img src="./img/team-1.jpg" />
                </figure>

                <div className="faculty-info">
                  <h3>Seemaa Khandelwal</h3>
                  <p>B.ed, Teacher trainer - Founder of Applee Tree Pre School</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
