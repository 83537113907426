import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";

export const Activity = () => {
  return (
    <>
      <section className="our-activities" id="activities">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="section-title wow fadeInUp"
                style={{visibility: "hidden", "animation-name": "none"}}
              >
                <h2 className="color-orange">Our Activities</h2>
                <p>Our Best Services for your Kids</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <div
                className="activities-single wow fadeInUp"
                data-wow-delay="0.3s"
                style={{
                "visibility": "hidden",
                "animation-delay": "0.3s",
                "animation-name": "none"
              }}
              >
                <div className="icon-box icon-blue">
                <i class="bi bi-patch-check-fill"></i>
                </div>

                <h3>Certified Teachers</h3>
              </div>
            </div>

            <div className="col-md-3">
              <div
                className="activities-single wow fadeInUp"
                data-wow-delay="0.6s"
                style={{
                "visibility": "hidden",
                "animation-delay": "0.6s",
                "animation-name": "none"
              }}
              >
                <div className="icon-box icon-orange">
                <i class="bi bi-book"></i>
                </div>

                <h3>Self Learning Environment</h3>
              </div>
            </div>

            <div className="col-md-3">
              <div
                className="activities-single wow fadeInUp"
                data-wow-delay="0.6s"
                style={{
                "visibility": "hidden",
                "animation-delay": "0.6s",
                "animation-name": "none"
              }}
              >
                <div className="icon-box icon-green">
                <i class="fa fa-graduation-cap" aria-hidden="true"></i>

                </div>

                <h3>Cognitive / Academic Learning</h3>
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="activities-single wow fadeInUp"
                data-wow-delay="0.6s"
                style={{
                "visibility": "hidden",
                "animation-delay": "0.6s",
                "animation-name": "none"
              }}
              >
                <div className="icon-box icon-blue">
                <i class="fa fa-child" aria-hidden="true"></i>

                </div>

                <h3>Parents App to check Child Performance</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
