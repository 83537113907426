
import { Navigation } from '../Navigation/Navigation';
import { Header } from '../Header/Header';
import { Banner } from '../Banner/Banner';
import { Footer } from '../Footer/Footer';
import { Activity } from '../Activity/Activity';
import { Courses } from '../Courses/Courses';
import { About } from '../About/About';
import { Faculty } from '../Faculty/Faculty';
import { Events } from '../Events/Events';
import { Testimonial } from '../Testimonial/Testimonial';
import { Gallery } from '../Gallery/Gallery';
import { Contact } from '../Contact/Contact';

const Home = () => {
  return (
    <div>
     <Header />
     <Banner />
     <Activity />
     <Courses />
     <About />
     <Faculty />
     <Events />
     <Gallery dashboard={true} />
     <Contact />
     <Footer />
    </div>
  );
}

export default Home;
