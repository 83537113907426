import React from "react";
import "./Events.css";

export const Events = () => {
  return (
    <>
      <section className="events" id="events">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2 className="color-orange">Upcoming Events</h2>
              </div>
            </div>
          </div>

          <div className="row event_gallery">
            <div className="col-md-12">
              <div className="swiper-container event-slider swiper-container-horizontal">
                <div
                  className="swiper-wrapper"
                  style={{
                    "transition-duration": "0ms",
                    transform: "translate3d(-1110px, 0px, 0px)",
                  }}
                >
                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                    data-swiper-slide-index="0"
                    style={{ width: "370px" }}
                  >
                    <div className="event-single event-orange">
                      <figure className="events_figure">
                        <img
                          className="events_img"
                          src="./img/classroom1.jpg"
                          alt=""
                        />
                      </figure>

                      <div className="event-body">
                        <h3>Classroom Pics</h3>
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
                    data-swiper-slide-index="1"
                    style={{ width: "370px" }}
                  >
                    <div className="event-single event-blue">
                      <figure className="events_figure">
                        <img
                          className="events_img"
                          src="./img/event-2.jpg"
                          alt=""
                        />
                      </figure>

                      <div className="event-body">
                        <h3>Guru Purnima</h3>
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-prev"
                    data-swiper-slide-index="2"
                    style={{ width: "370px" }}
                  >
                    <div className="event-single event-green">
                      <figure className="events_figure">
                        <img
                          className="events_img"
                          src="./img/event-3.jpg"
                          alt=""
                        />
                      </figure>

                      <div className="event-body">
                        <h3>Outdor Music Event</h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="swiper-slide swiper-slide-active"
                    data-swiper-slide-index="0"
                    style={{ width: "370px" }}
                  >
                    <div className="event-single event-orange">
                      <figure className="events_figure">
                      <a href="./img/event-2.jpg">
                            <img
                              src="./img/event-2.jpg"
                              className="gallery_img"
                            />
                          </a>
                      </figure>

                      <div className="event-body">
                        <h3>Transportation Activity1</h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="swiper-slide swiper-slide-next"
                    data-swiper-slide-index="1"
                    style={{ width: "370px" }}
                  >
                    <div className="event-single event-blue">
                      <figure className="events_figure">
                      <a href="./img/event-5.jpg">
                        <img
                          className="events_img modal-content"
                          src="./img/event-5.jpg"
                          alt=""
                        />
                        </a>
                      </figure>

                      <div className="event-body">
                        <h3>Guru Purnima</h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="swiper-slide swiper-slide-duplicate-prev"
                    data-swiper-slide-index="2"
                    style={{ width: "370px" }}
                  >
                    <div className="event-single event-green">
                      <figure className="events_figure">
                      <a href="./img/event-6.jpg">
                        <img
                          className="events_img"
                          src="./img/event-6.jpg"
                          alt=""
                        />
                        </a>
                      </figure>

                      <div className="event-body">
                        <h3>Fancy Dress Competition</h3>
                      </div>
                    </div>
                  </div>

                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                    data-swiper-slide-index="0"
                    style={{ width: "370px" }}
                  >
                    <div className="event-single event-orange">
                      <figure className="events_figure">
                        <img
                          className="events_img"
                          src="./img/classroom1.jpg"
                          alt=""
                        />
                      </figure>

                      <div className="event-body">
                        <h3>classroom Pics</h3>
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
                    data-swiper-slide-index="1"
                    style={{ width: "370px" }}
                  >
                    <div className="event-single event-blue">
                      <figure className="events_figure">
                        <img
                          className="events_img"
                          src="./img/classroom2.jpg"
                          alt=""
                        />
                      </figure>

                      <div className="event-body">
                        <h3>classroom Pic</h3>
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    data-swiper-slide-index="2"
                    style={{ width: "370px" }}
                  >
                    <div className="event-single event-green">
                      <figure className="events_figure">
                        <img
                          className="events_img"
                          src="./img/event-3.jpg"
                          alt=""
                        />
                      </figure>

                      <div className="event-body">
                        <h3>Fancy Dress Competition</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <button id="event-more" className="btn-more">Check More</button>
                <div className="event-pagination swiper-pagination-clickable swiper-pagination-bullets">
                  <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                  <span className="swiper-pagination-bullet"></span>
                  <span className="swiper-pagination-bullet"></span>
                </div>
                
              </div>
              
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
};
