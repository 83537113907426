import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    activityType: 0
}

export const activitySlice = createSlice({
    name: 'couseSlice',
    initialState,
    reducers: {
        selectActivity: (state, action) => {
            state.activityType = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { selectActivity } = activitySlice.actions

export default activitySlice.reducer
