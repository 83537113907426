import React from "react";
import "./Gallery.css";

export const Gallery = (props) => {
  return (
    <>
      <section className="photo-gallery" id="gallery">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2 className="color-orange">Photo Gallery</h2>
              </div>
            </div>
          </div>

          {props.dashboard && (
            <div className="row gallery">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div
                  className="photo-single photo-blue wow fadeInUp"
                  data-wow-delay="0.6s"
                  style={{
                    visibility: "hidden",
                    "animation-delay": "0.6s",
                    "animation-name": "none",
                  }}
                >
                  <a href="./img/course-2.jpg">
                    <img src="./img/course-2.jpg" className="gallery_img" />
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6">
                <div
                  className="photo-single photo-green wow fadeInUp"
                  data-wow-delay="0.9s"
                  style={{
                    visibility: "hidden",
                    "animation-delay": "0.9s",
                    "animation-name": "none",
                  }}
                >
                  <a href="./img/course-3.jpg">
                    <img src="./img/course-3.jpg" className="gallery_img" />
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6">
                <div
                  className="photo-single photo-orange wow fadeInUp"
                  data-wow-delay="1.2s"
                  style={{
                    visibility: "hidden",
                    "animation-delay": "1.2s",
                    "animation-name": "none",
                  }}
                >
                  <a href="./img/course-4.jpg">
                    <img src="./img/course-4.jpg" className="gallery_img" />
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6">
                <div
                  className="photo-single photo-blue wow fadeInUp"
                  data-wow-delay="1.5s"
                  style={{
                    visibility: "hidden",
                    "animation-delay": "1.5s",
                    "animation-name": "none",
                  }}
                >
                  <a href="./img/course-5.jpg">
                    <img src="./img/course-5.jpg" className="gallery_img" />
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6">
                <div
                  className="photo-single photo-green wow fadeInUp"
                  data-wow-delay="1.8s"
                  style={{
                    visibility: "hidden",
                    "animation-delay": "1.8s",
                    "animation-name": "none",
                  }}
                >
                  <a href="./img/course-6.jpg">
                    <img src="./img/course-6.jpg" className="gallery_img" />
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6">
                <div
                  className="photo-single photo-orange wow fadeInUp"
                  data-wow-delay="2.1s"
                  style={{
                    visibility: "hidden",
                    "animation-delay": "2.1s",
                    "animation-name": "none",
                  }}
                >
                  <a href="./img/event-3.jpg">
                    <img src="./img/event-3.jpg" className="gallery_img" />
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6">
                <div
                  className="photo-single photo-blue wow fadeInUp"
                  data-wow-delay="2.4s"
                  style={{
                    visibility: "hidden",
                    "animation-delay": "2.4s",
                    "animation-name": "none",
                  }}
                >
                  <a href="./img/course-2.jpg">
                    <img src="./img/course-2.jpg" className="gallery_img" />
                  </a>
                </div>
              </div>
            </div>
          )}
          {!props.dashboard && (
            <>
              <div className="row gallery">
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="photo-single photo-blue wow fadeInUp"
                    data-wow-delay="0.6s"
                    style={{
                      visibility: "hidden",
                      "animation-delay": "0.6s",
                      "animation-name": "none",
                    }}
                  >
                    <a href="./img/course-2.jpg">
                      <img src="./img/course-2.jpg" className="gallery_img" />
                    </a>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="photo-single photo-green wow fadeInUp"
                    data-wow-delay="0.9s"
                    style={{
                      visibility: "hidden",
                      "animation-delay": "0.9s",
                      "animation-name": "none",
                    }}
                  >
                    <a href="./img/course-3.jpg">
                      <img src="./img/course-3.jpg" className="gallery_img" />
                    </a>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="photo-single photo-orange wow fadeInUp"
                    data-wow-delay="1.2s"
                    style={{
                      visibility: "hidden",
                      "animation-delay": "1.2s",
                      "animation-name": "none",
                    }}
                  >
                    <a href="./img/course-4.jpg">
                      <img src="./img/course-4.jpg" className="gallery_img" />
                    </a>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="photo-single photo-blue wow fadeInUp"
                    data-wow-delay="1.5s"
                    style={{
                      visibility: "hidden",
                      "animation-delay": "1.5s",
                      "animation-name": "none",
                    }}
                  >
                    <a href="./img/course-5.jpg">
                      <img src="./img/course-5.jpg" className="gallery_img" />
                    </a>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="photo-single photo-green wow fadeInUp"
                    data-wow-delay="1.8s"
                    style={{
                      visibility: "hidden",
                      "animation-delay": "1.8s",
                      "animation-name": "none",
                    }}
                  >
                    <a href="./img/course-6.jpg">
                      <img src="./img/course-6.jpg" className="gallery_img" />
                    </a>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="photo-single photo-orange wow fadeInUp"
                    data-wow-delay="2.1s"
                    style={{
                      visibility: "hidden",
                      "animation-delay": "2.1s",
                      "animation-name": "none",
                    }}
                  >
                    <a href="./img/event-3.jpg">
                      <img src="./img/event-3.jpg" className="gallery_img" />
                    </a>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="photo-single photo-blue wow fadeInUp"
                    data-wow-delay="2.4s"
                    style={{
                      visibility: "hidden",
                      "animation-delay": "2.4s",
                      "animation-name": "none",
                    }}
                  >
                    <a href="./img/course-2.jpg">
                      <img src="./img/course-2.jpg" className="gallery_img" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="row gallery">
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="photo-single photo-blue wow fadeInUp"
                    data-wow-delay="0.6s"
                    style={{
                      visibility: "hidden",
                      "animation-delay": "0.6s",
                      "animation-name": "none",
                    }}
                  >
                    <a href="./img/course-2.jpg">
                      <img src="./img/course-2.jpg" className="gallery_img" />
                    </a>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="photo-single photo-green wow fadeInUp"
                    data-wow-delay="0.9s"
                    style={{
                      visibility: "hidden",
                      "animation-delay": "0.9s",
                      "animation-name": "none",
                    }}
                  >
                    <a href="./img/course-3.jpg">
                      <img src="./img/course-3.jpg" className="gallery_img" />
                    </a>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="photo-single photo-orange wow fadeInUp"
                    data-wow-delay="1.2s"
                    style={{
                      visibility: "hidden",
                      "animation-delay": "1.2s",
                      "animation-name": "none",
                    }}
                  >
                    <a href="./img/course-4.jpg">
                      <img src="./img/course-4.jpg" className="gallery_img" />
                    </a>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="photo-single photo-blue wow fadeInUp"
                    data-wow-delay="1.5s"
                    style={{
                      visibility: "hidden",
                      "animation-delay": "1.5s",
                      "animation-name": "none",
                    }}
                  >
                    <a href="./img/course-5.jpg">
                      <img src="./img/course-5.jpg" className="gallery_img" />
                    </a>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="photo-single photo-green wow fadeInUp"
                    data-wow-delay="1.8s"
                    style={{
                      visibility: "hidden",
                      "animation-delay": "1.8s",
                      "animation-name": "none",
                    }}
                  >
                    <a href="./img/course-6.jpg">
                      <img src="./img/course-6.jpg" className="gallery_img" />
                    </a>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="photo-single photo-orange wow fadeInUp"
                    data-wow-delay="2.1s"
                    style={{
                      visibility: "hidden",
                      "animation-delay": "2.1s",
                      "animation-name": "none",
                    }}
                  >
                    <a href="./img/event-3.jpg">
                      <img src="./img/event-3.jpg" className="gallery_img" />
                    </a>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="photo-single photo-blue wow fadeInUp"
                    data-wow-delay="2.4s"
                    style={{
                      visibility: "hidden",
                      "animation-delay": "2.4s",
                      "animation-name": "none",
                    }}
                  >
                    <a href="./img/course-2.jpg">
                      <img src="./img/course-2.jpg" className="gallery_img" />
                    </a>
                  </div>
                </div>
              </div>
            </>
          )}
          <button id="gallery-more" className="btn-more">
            Check More
          </button>
        </div>
      </section>
    </>
  );
};
