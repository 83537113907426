import React from "react";
import "./Courses.css";

export const Courses = () => {
  return (
    <>
      <section className="courses" id="courses">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2 className="color-orange">Our Courses</h2>
                <p>Our Courses for your Kids</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div
                className="course-single course-orange wow fadeInUp"
                data-wow-delay="0.3s"
                style={{
                  visibility: "hidden",
                  "animation-delay": "0.3s",
                  "animation-name": "none",
                }}
              >
                <figure className="courses_figure">
                  <img
                    className="courses_img"
                    src="./img/play-group/img1.jpeg"
                  />
                </figure>

                <div className="course-body-wrap">
                  <div className="course-body">
                    <h3>Play Group</h3>
                  </div>
                  <button id="playgroup-more" className="btn-more">
                    Check More
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div
                className="course-single course-blue wow fadeInUp"
                data-wow-delay="0.6s"
                style={{
                  visibility: "hidden",
                  "animation-delay": "0.6s",
                  "animation-name": "none",
                }}
              >
                <figure className="courses_figure">
                  <img className="courses_img" src="./img/nursery/img1.jpeg" />
                </figure>
                <div className="course-body-wrap">
                  <div className="course-body">
                    <h3>Nursery</h3>
                  </div>
                  <button id="nursery-more" className="btn-more">
                    Check More
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-lg-6">
              <div
                className="course-single course-blue wow fadeInUp"
                data-wow-delay="0.6s"
                style={{
                  visibility: "hidden",
                  "animation-delay": "0.6s",
                  "animation-name": "none",
                }}
              >
                <figure className="courses_figure">
                  <img className="courses_img" src="./img/jr-kg/img1.jpg" />
                </figure>
                <div className="course-body-wrap">
                  <div className="course-body">
                    <h3>Jr KG</h3>
                  </div>
                  <button id="jr-more" className="btn-more">
                    Check More
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div
                className="course-single course-green wow fadeInUp"
                data-wow-delay="0.9s"
                style={{
                  visibility: "hidden",
                  "animation-delay": "0.9s",
                  "animation-name": "none",
                }}
              >
                <figure className="courses_figure">
                  <img className="courses_img" src="./img/sr-kg/img1.jpeg" />
                </figure>
                <div className="course-body-wrap">
                  <div className="course-body">
                    <h3>Sr KG</h3>
                  </div>
                  <button id="jr-more" className="btn-more">
                    Check More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
