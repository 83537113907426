import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FloatingWhatsApp } from "react-floating-whatsapp";

export const Footer = () => {
  const [isload, setLoad] = useState(0);
  const dispatch = useDispatch();
  const isloaded = useRef(null);
  return (
    <>
      <footer className="footer">  
      <div ref={isloaded} style={{ display: "none" }} />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-logo">
                <img src="./img/logo.jpg" alt="" />
              </div>
              <br />
              <div className="about-social-links">
                <a
                  href="https://www.facebook.com/appleetreepreschool/"
                  target="_blank"
                >
                  <i className="fa fa-facebook"></i>
                </a>
                <a
                  href="https://youtube.com/@appletree5735?feature=shared"
                  target="_blank"
                >
                  <i className="fa fa-youtube"></i>
                </a>
                <a href="#">
                  <i className="fa fa-linkedin"></i>
                </a>
                <a href="#">
                  <i className="fa fa-instagram"></i>
                </a>
              </div>
              <div className="footer-copyright">
                <p>
                  Copyright © Untitled. All rights reserved. Design By
                  AppleeTree Preschool
                </p>
              </div>
            </div>
          </div>
        </div>
        <FloatingWhatsApp
          phoneNumber="7977715240"
          accountName="AppleeTree PreSchool"
          allowEsc
          allowClickAway
          notification
          notificationSound
        />
      </footer>
    </>
  );
};
