import React from "react";
import "./Banner.css";

export const Banner = (props) => {
  return (
    <>
      <div className="banner-slider" id="home">
        <div className="container-fluid">
          <div className="row no-gutters">
            <div className="col-md-12">
              <div className={`swiper-container banner-slider swiper-container-fade swiper-container-horizontal ${props.slideclass}`}>
                <div className="swiper-wrapper" style={{"transition-duration": "0ms"}}>
                  <div
                    className="swiper-slide swiper-slide-active"
                    style={{
                    width: "1281px",
                    opacity: 1,
                    transform: "translate3d(0px, 0px, 0px)",
                    "transition-duration": "0ms"
                  }}
                  >
                    <div className="banner-slide">
                      <figure>
                        <img src="./img/slide-1.jpeg" className="banner_img" />
                      </figure>

                      <div className="banner-slide-overlay">
                        <h2
                          className="color-orange fadeInDown animated"
                          data-swiper-animation="fadeInDown"
                          data-duration="1s"
                          data-delay="1.5s"
                          style={{
                          "visibility": "visible",
                          "animation-duration": "1s",
                          "animation-delay": "1.5"}}
                        >
                          Applee Tree - Preschool With Qualified Faculty Member
                        </h2>
                        <a
                          href="#contact"
                          className="btn-slider fadeInUp animated"
                          data-swiper-animation="fadeInUp"
                          data-duration="1s"
                          data-delay="2.5s"
                          style={{
                          visibility: "visible",
                          "animation-duration": "1s",
                          "animation-delay": "2.5s"
                        }}
                        >
                          Enroll Today !
                        </a>
                      </div>
                    </div>
                  </div>

                  <div
                    className="swiper-slide swiper-slide-next"
                    style={{
                    width: "1281px",
                    opacity: 0,
                    transform: "translate3d(-1281px, 0px, 0px)",
                    "transition-duration": "0ms"
                  }}
                  >
                    <div className="banner-slide">
                      <figure>
                        <img src="./img/slide-2.jpeg" className="banner_img" />
                      </figure>

                      <div
                        className="banner-slide-overlay"
                        data-swiper-animation="zoomIn"
                        data-duration="1s"
                        data-delay="0.5s"
                        style={{visibility: "hidden"}}
                      >
                        <h2
                          className="color-orange"
                          data-swiper-animation="fadeInDown"
                          data-duration="1s"
                          data-delay="1.5s"
                          style={{visibility: "hidden"}}
                        >
                          Applee Tree - Preschool with Playgroup / Nursery / Jr.KG / Sr.KG
                        </h2>
                        <a
                          href="#contact"
                          className="btn-slider"
                          data-swiper-animation="fadeInUp"
                          data-duration="1s"
                          data-delay="2.5s"
                          style={{visibility: "hidden"}}
                        >
                          Enroll Today !
                        </a>
                      </div>
                    </div>
                  </div>

                  <div
                    className="swiper-slide"
                    style={{
                    width: "1281px",
                    opacity: 0,
                    transform: "translate3d(-2562px, 0px, 0px)",
                    "transition-duration": "0ms"
                  }}
                  >
                    <div className="banner-slide">
                      <figure>
                        <img src="./img/slide-3.jpg" className="banner_img" />
                      </figure>

                      <div
                        className="banner-slide-overlay"
                        data-swiper-animation="zoomIn"
                        data-duration="1s"
                        data-delay="0.5s"
                        style={{visibility: "hidden"}}
                      >
                        <h2
                          className="color-blue"
                          data-swiper-animation="fadeInDown"
                          data-duration="1s"
                          data-delay="1.5s"
                          style={{visibility: "hidden"}}
                        >
                          Applee Tree - Preschool With Quality of education
                        </h2>
                        <a
                          href="#contact"
                          className="btn-slider"
                          data-swiper-animation="fadeInUp"
                          data-duration="1s"
                          data-delay="2.5s"
                          style={{visibility: "hidden"}}
                        >
                          Enroll Today !
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="banner-button-prev swiper-button-disabled">
                  <i className="fa fa-chevron-left"></i>
                </div>
                <div className="banner-button-next">
                  <i className="fa fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
