import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    courseType: 0
}

export const courseSlice = createSlice({
    name: 'couseSlice',
    initialState,
    reducers: {
        changeCourse: (state, action) => {
            state.courseType = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { changeCourse } = courseSlice.actions

export default courseSlice.reducer
