import React from "react";

export const Navigation = () => {
  return (
    <>
      <div className="top-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="header-social-link">
                <a href="#">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="#">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fa fa-linkedin"></i>
                </a>
                <a href="#">
                  <i className="fa fa-instagram"></i>
                </a>
              </div>
            </div>

            <div className="col-md-8 text-right">
              <div className="school-info">
                <ul>
                  <li>
                    <a href="#">
                      <i className="fa fa-envelope"></i> Email :
                      example@domain.com
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-phone"></i> Call : +28 232 574 1
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
