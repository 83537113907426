import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeCourse } from "../Reducers/courseReducer";
import { Link } from "react-router-dom";
import "./Header.css";
import { selectActivity } from "../Reducers/activityReducer";

export const Header = () => {
  const [isload, setLoad] = useState(0);
  const dispatch = useDispatch();
  const isloaded = useRef(null);
  useEffect(() => {
    if (isloaded.current.innerHTML !== "loaded") {
      let scripts = [
        "./js/jquery-1.js",
        "./js/bootstrap.js",
        "./js/wow.js",
        "./js/swiper.js",
        "./js/SwiperAnimation.js",
        "./js/jquery_002.js",
        "./js/jquery.js",
        "./js/SmoothScroll.js",
        "./js/function.js",
      ];

      scripts.forEach(function (url) {
        let script = document.createElement("script");
        script.src = url;
        script.async = false;
        document.body.appendChild(script);
      });
      isloaded.current.innerHTML = "loaded";
      let count = isload;
      setLoad(++count);
    }
  }, []);
  return (
    <>
      <header className="header">
        <div ref={isloaded} style={{ display: "none" }} />
        <nav className="navbar navbar-expand-lg navbar-light" id="main-navbar">
          <div className="container">
            <a className="navbar-brand" href="#">
              <img src="./img/logo.jpg" className="header_logo" alt="" />
            </a>

            <ul className="navbar-nav ml-auto" id="main-menu">
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link">
                  About Us
                </Link>
              </li>
              <li className="nav-item dropdown dropend" id="activity-menu">
                <a
                  className="nav-link  dropdown-toggle"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  Activities
                </a>
                <ul className="dropdown-menu activity-menu">
                  <li className="dropdown-menu-list">
                    <Link
                      className="dropdown-item"
                      to="/activity"
                      onClick={() => dispatch(selectActivity(0))}
                    >
                      Certified Teachers
                    </Link>
                  </li>
                  <li className="dropdown-menu-list">
                    <Link
                      className="dropdown-item"
                      to="/activity"
                      onClick={() => dispatch(selectActivity(1))}
                    >
                      Self Learning Environment
                    </Link>
                  </li>
                  <li className="dropdown-menu-list">
                    <Link
                      className="dropdown-item"
                      to="/activity"
                      onClick={() => dispatch(selectActivity(2))}
                    >
                      Cognitive / Academic Learning
                    </Link>
                  </li>
                  <li className="dropdown-menu-list">
                    <Link
                      className="dropdown-item"
                      to="/activity"
                      onClick={() => dispatch(selectActivity(3))}
                    >
                      Parents App to check Child Performance
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown dropend" id="courses-menu">
                <a
                  className="nav-link  dropdown-toggle"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  Courses
                </a>
                <ul className="dropdown-menu courses-menu">
                  <li className="dropdown-menu-list">
                    <Link
                      className="dropdown-item"
                      to="/courses"
                      onClick={() => dispatch(changeCourse(0))}
                    >
                      Play Group
                    </Link>
                  </li>
                  <li className="dropdown-menu-list">
                    <Link
                      className="dropdown-item"
                      to="/courses"
                      onClick={() => dispatch(changeCourse(1))}
                    >
                      Nursery
                    </Link>
                  </li>
                  <li className="dropdown-menu-list">
                    <Link
                      className="dropdown-item"
                      to="/courses"
                      onClick={() => dispatch(changeCourse(2))}
                    >
                      Jr KG
                    </Link>
                  </li>
                  <li className="dropdown-menu-list">
                    <Link
                      className="dropdown-item"
                      to="/courses"
                      onClick={() => dispatch(changeCourse(3))}
                    >
                      Sr KG
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/faculty">
                  Teachers
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/events">
                  Events
                </Link>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#review">
                  Review
                </a>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/gallery">
                  Gallery
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>

            <div className="navbar-toggle">
              <a
                href="#"
                aria-haspopup="true"
                role="button"
                tabindex="0"
                className="slicknav_btn slicknav_collapsed"
                style={{ outline: "currentcolor none medium" }}
              >
                <span className="slicknav_menutxt"></span>
                <span className="slicknav_icon slicknav_no-text">
                  <span className="slicknav_icon-bar"></span>
                  <span className="slicknav_icon-bar"></span>
                  <span className="slicknav_icon-bar"></span>
                </span>
              </a>
            </div>
            <div id="responsive-menu">
              <div className="slicknav_menu">
                <ul
                  className="slicknav_nav slicknav_hidden"
                  style={{ display: "none" }}
                  aria-hidden="true"
                  role="menu"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      href="#home"
                      role="menuitem"
                      tabindex="-1"
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#activities"
                      role="menuitem"
                      tabindex="-1"
                    >
                      Activities
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#courses"
                      role="menuitem"
                      tabindex="-1"
                    >
                      Courses
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#teachers"
                      role="menuitem"
                      tabindex="-1"
                    >
                      Teachers
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#events"
                      role="menuitem"
                      tabindex="-1"
                    >
                      Events
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#review"
                      role="menuitem"
                      tabindex="-1"
                    >
                      Review
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#gallery"
                      role="menuitem"
                      tabindex="-1"
                    >
                      Gallery
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#contact"
                      role="menuitem"
                      tabindex="-1"
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
