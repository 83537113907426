import React,{useEffect,useState} from "react";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import axios from "axios";
import 'react-notifications/lib/notifications.css';

export const Contact = () => {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [query, setQuery] = useState(null);


  const saveEnquiry = () => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phoneNumber', phoneNumber);
    formData.append('query', query);
    
    console.log("data: ", formData);
    if(name && email && phoneNumber && query) {
      const config = {
        method: 'post',
        data: formData,
        url: 'http://www.appleetreepreschool.com/Contact.php',
      };
      axios(config)
      .then((response) => {
        NotificationManager.success('Enquiry submitted successfully', '');
      })
      .catch((error) => {
        NotificationManager.error('Please enter valid details', '', 5000, () => {
          alert('callback');
        });
      });
    }
    else {
      NotificationManager.error('Please enter all details', '', 5000, () => {
        alert('callback');
      });
    }
  }

  return (
    <>
      <section className="contactus" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="section-title">
                <h2 className="color-orange">Information</h2>
                {/* <p>Lorem ipsum dolor sit amet, ipsum dolor sit amet</p> */}
              </div>

              <div className="contact-info">
                <p className="email">admin@appleetreepreschool.com</p>
                <p className="contact">7977715240 / 9324311920</p>
                <p className="address">
                  A1 Ananthsagar Adharwadi Jail Road Near Ronak City Village
                  Wade ghar Kalyan (w)
                </p>
              </div>

              <div className="contact-social-link">
                <a href="#">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="#">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fa fa-linkedin"></i>
                </a>
                <a href="#">
                  <i className="fa fa-instagram"></i>
                </a>
              </div>
            </div>

            <div className="col-md-6">
              <div className="section-title">
                <h2 className="color-orange">Contact Us</h2>
                {/* <p>Lorem ipsum dolor sit amet, ipsum dolor sit amet</p> */}
              </div>

              <div className="contact-form">
                <form id="contactForm" method="post">
                  <div className="row">
                    <div className="form-group col-md-6 col-sm-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Name"
                        value={name}
                        name="name"
                        onChange={(e)=>setName(e.target.value)}
                      />
                    </div>

                    <div className="form-group col-md-6 col-sm-6">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Your Email Address"
                        value={email}
                        name="email"
                        onChange={(e)=>setEmail(e.target.value)}
                      />
                    </div>

                    <div className="form-group col-md-12 col-sm-12">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Mobile Number"
                        value={phoneNumber}
                        name="phoneNumber"
                        onChange={(e)=>setPhoneNumber(e.target.value)}
                      />
                    </div>

                    <div className="form-group col-md-12 col-sm-12">
                      <textarea
                        rows="4"
                        className="form-control"
                        placeholder="How can i help you?"
                        value={query}
                        name="query"
                        onChange={(e)=>setQuery(e.target.value)}
                      ></textarea>
                    </div>

                    <div className="col-md-12 col-sm-12 text-center">
                      <button type="button" className="btn-contact" id="contact_submit" onClick={saveEnquiry}>
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NotificationContainer />
    </>
  );
};
