import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import "./App.css";
import Home from "./Home/Home";
import Page from "./Page/Page";
import CoursesMenu from "./CoursesMenu/CoursesMenu";
import { Header } from "./Header/Header";
import { Footer } from "./Footer/Footer";
import  ActivityMenu  from "./ActivityMenu/ActivityMenu";
import { About } from "./About/About";
import { Gallery } from "./Gallery/Gallery";
import { Contact } from "./Contact/Contact";
import { Events } from "./Events/Events";
import { Faculty } from "./Faculty/Faculty";

function App() {
  return (
    <Provider store={store}>
      <div className="App" id="root">
        <Router>
          {/* <Header /> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/about"
              element={
                <>
                  <Header />
                  <About />
                  <Footer />
                </>
              }
            />
            <Route
              path="/gallery"
              element={
                <>
                  <Header />
                  <Gallery dashboard={false} />
                  <Footer />
                </>
              }
            />
            <Route
              path="/contact"
              element={
                <>
                  <Header />
                  <Contact />
                  <Footer />
                </>
              }
            />
            <Route
              path="/events"
              element={
                <>
                  <Header />
                  <Events />
                  <Footer />
                </>
              }
            />
            <Route
              path="/faculty"
              element={
                <>
                  <Header />
                  <Faculty />
                  <Footer />
                </>
              }
            />
            <Route path="/courses" exact element={<CoursesMenu />} />
            <Route path="/activity" exact element={<ActivityMenu />} />
          </Routes>
          {/* <Footer /> */}
        </Router>
      </div>
    </Provider>
  );
}

export default App;
