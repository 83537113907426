import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { Banner } from "../Banner/Banner";
import { RouteAuthentication } from "../RouteAuthentication/RouteAuthentication";
import "./CoursesMenu.css";

const CoursesMenu = () => {
  const cousertype = useSelector((state) => state.course.courseType);
  console.log("cousertype: ", cousertype);
  return (
    <div className="page">
      <Header />
      {/* <Banner slideclass="course-slide" /> */}
      <section className="about-us">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2 className="color-orange">
                {cousertype === 0 && "Play Group"}
               {cousertype === 1 && "Nursery"}
               {cousertype === 2 && "Jr KG"}
               {cousertype === 3 && "Sr KG"}
                </h2>
                {/* <p>Lorem ipsum dolor sit amet, ipsum dolor sit amet</p> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div
                className="about-image wow fadeInLeft"
                data-wow-delay="0.3s"
                style={{
                  visibility: "hidden",
                  "animation-delay": "0.3s",
                  "animation-name": "none",
                }}
              >
              {cousertype === 0 && <img src="./img/play-group/img1.jpeg" />}
               {cousertype === 1 && <img src="./img/nursery/nursery.jpeg" />}
               {cousertype === 2 && <img src="./img/jr-kg/jr-kg.jpeg" />}
               {cousertype === 3 && <img src="./img/sr-kg/img1.jpeg" />}
              </div>
            </div>

            <div className="col-md-6">
              <div
                className="course-content wow fadeInUp"
                data-wow-delay="0.6s"
                style={{
                  visibility: "hidden",
                  "animation-delay": "0.6s",
                  "animation-name": "none",
                }}
              >
                <h3>Our Philosophy</h3>

                {cousertype === 0 ? (
                  <p>
                    Applee Tree Pre School teach the basics to kids, giving them
                    a strong foundation for the elementary years. This includes
                    academic concepts of literacy and math, such as counting,
                    coloring, and letter recognition and developing large and
                    fine motor skills, such as walking in a line and using a
                    pencil.
                  </p>
                ) : null}
                {cousertype === 1 ? (
                  <>
                    <p>At Applee Tree PreSchool, we are very well aware that the
                    ideal nursery curriculum should be flexible and adaptable to
                    meet the individual needs and interest of the children,
                    while also considering cultural and community factors. </p><p>Play
                    based learning, Language and literacy development, basic
                    mathematical concepts, fine and motor skills Development,
                    Social and emotional development, science and Nature
                    exploration, creative arts, Health and well being, parental
                    involvement.</p>
                  </>
                ) : null}
                {cousertype === 2 ? (
                  <>
                    <p>The Jr kg Lab is designed for vibrant and active toddlers.</p>
                    <p>Lab materials are utilized to engaged children in their
                    learning experience.</p><p> Language and Math skills are
                    prioritized with a focus on phonics, reading, spelling and
                    writing. The jr kg programs aims to build a solid foundation
                    for formal school education, balancing academics with
                    essential skills and over all development.</p> <p>The program
                    emphasizes intensive reading and writing, enhancing these
                    crucial skills for children at this stage.</p>
                  </>
                ) : null}
                {cousertype === 3 ? (
                  <>
                    <p>The Jr kg Lab is designed for vibrant and active toddlers.</p>
                    <p>Lab materials are utilized to engaged children in their
                    learning experience.</p><p> Language and Math skills are
                    prioritized with a focus on phonics, reading, spelling and
                    writing. The jr kg programs aims to build a solid foundation
                    for formal school education, balancing academics with
                    essential skills and over all development.</p><p> The program
                    emphasizes intensive reading and writing, enhancing these
                    crucial skills for children at this stage.</p>
                  </>
                ) : null}
              </div>

              <div className="about-social-links">
                <a
                  href="https://www.facebook.com/appleetreepreschool/"
                  target="_blank"
                >
                  <i className="fa fa-facebook"></i>
                </a>
                <a
                  href="https://youtube.com/@appletree5735?feature=shared"
                  target="_blank"
                >
                  <i className="fa fa-youtube"></i>
                </a>
                <a href="#">
                  <i className="fa fa-linkedin"></i>
                </a>
                <a href="#">
                  <i className="fa fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default CoursesMenu;
