import Home from "../Home/Home";

export const RouteAuthentication = (Component) => {
  const AuthRoute = () => {
    
    const isAuth = !!localStorage.getItem("token");
    if (isAuth) {
      return <Component />;
    }
    else {
      return <Home />
    }
  };

  return AuthRoute;
};
